<template>
  <SetLoader v-if="!formShow" />
  <form v-if="formShow" class="form w-100" id="newForm">
    <HiddenFields crudType="New" :pageData="pageData" />
    <RecordItem :pageData="pageData" :recordItems="savedRecords" />
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <AlertBox type="warning" :messages="responseError" />
          <ValidationMessageList
            v-if="insertResponseValidationModel.showValidations"
            :isModal="false"
            :msg="insertResponseValidationModel.msg"
            :message="insertResponseValidationModel.message"
            :errors="insertResponseValidationModel.errors"
            :warnings="insertResponseValidationModel.warnings"
            :informations="insertResponseValidationModel.informations"
          />
          <ValidationMessageListInModal
            :windowOpener="windowOpener"
            :errors="insertResponseValidationModel.modalErrors"
            :warnings="insertResponseValidationModel.modalWarnings"
            :informations="insertResponseValidationModel.modalInformations"
            :businessRuleErrors="
              insertResponseValidationModel.businessRuleErrors
            "
          />
        </div>
      </div>
    </div>
    <DesignItem
      v-for="element in designedItems"
      :key="element.id"
      :element="element"
      :items="designedItems"
    />
    <ActionButtons
      @methodSave="onRecordInsert('0', 'btn-save')"
      @methodSaveAndNew="onRecordInsert('1', 'btn-save-and-new')"
      @methodSaveAndClose="onRecordInsert('2', 'btn-save-and-close')"
      @methodSaveAndContinue="onRecordInsert('3', 'btn-save-and-continue')"
      @clear="onClear"
      :isToolbar="false"
      :classes="{
        'ps-0 p-2 hidden': !$isMobile(),
        hidden: $isMobile(),
      }"
    />
  </form>
</template>
<script>
import { createToast } from "mosha-vue-toastify";
import $ from "jquery";
import DesignItem from "@/components/set-design/DesignItem.vue";
import HiddenFields from "@/components/set-design/form-items/HiddenFields.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import RecordItem from "@/components/custom/set-pages/RecordItem.vue";
import ValidationMessageList from "@/components/custom/validation-message/List.vue";
import ValidationMessageListInModal from "@/components/custom/validation-message/Modal.vue";
import { showModal } from "@/core/helpers/dom";
export default {
  name: "NEW",
  props: ["key", "pageLayoutId"],
  data() {
    return {
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "BaseModelFields.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      successMessage: [
        this.$t(
          "BaseModelFields.SuccessMessage",
          {},
          { locale: this.$store.state.activeLang }
        ),
      ],
      disabledButtonClasses:
        ".btn-save, .btn-save-and-new, .btn-save-and-continue, .btn-save-and-close, .btn-clear",
      pageData: {},
      designedItems: [],
      savedRecords: [],
      formPosting: false,
      formShow: false,
      isSavedData: false,
      responseError: [],
      model: {},
      insertResponseValidationModel: {},
      windowOpener: window.opener,
    };
  },
  components: {
    DesignItem,
    HiddenFields,
    RecordItem,
    ValidationMessageList,
    ValidationMessageListInModal,
  },
  mounted() {
    this.getNewPage();

    if (window.opener) {
      this.prepareWindowOpenerForm();
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isSavedData && this.$root.checkUnSavedData("#newForm")) {
      const answer = window.confirm(
        this.$t(
          "BaseModelFields.AlertUnSavedData",
          {},
          { locale: this.$store.state.activeLang }
        )
      );
      if (answer) {
        this.checkWindowOpenerForm();
        next();
      } else {
        next(false);
      }
    } else {
      this.checkWindowOpenerForm();
      next();
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (!this.isSavedData && this.$root.checkUnSavedData("#newForm")) {
      const answer = window.confirm(
        this.$t(
          "BaseModelFields.AlertUnSavedData",
          {},
          { locale: this.$store.state.activeLang }
        )
      );
      if (answer) {
        this.checkWindowOpenerForm();
        next();
      } else {
        next(false);
      }
    } else {
      this.checkWindowOpenerForm();
      next();
    }
  },
  watch: {
    "$route.params.key"() {
      if (this.$route.name === "new" && this.formShow) {
        this.getNewPage();
      }
    },
    "$route.query.pageLayoutId"() {
      if (this.$route.name === "new" && this.formShow) {
        this.getNewPage();
      }
    },
  },
  methods: {
    checkWindowOpenerForm() {
      if (window.opener) {
        window.opener = null;
        localStorage.removeItem("NewLookupTabData");
        localStorage.removeItem("LookupInfo");
        localStorage.removeItem("TriggeredData");
      }
    },
    getNewPage() {
      setCurrentPageBreadcrumbs("", [{ name: "HELP" }]);
      this.$store.commit("setPageLayoutAvailable", false);
      this.responseError = [];
      this.insertResponseValidationModel = {};
      this.designedItems = [];
      this.formShow = false;
      this.isSavedData = false;
      this.$root.getSetScripts(this.$route.params.key, "New").then(() => {
        this.pageProcess();
      });
    },
    pageProcess() {
      var key = this.$route.params.key,
        pageLayoutId = this.$route.query.pageLayoutId;

      if (String.isNullOrWhiteSpace(key)) {
        this.$router.push(this.$appHomeUrl);
        return;
      }

      var newPageRequestUrl = `rws-SetController-NewPage?key=${key}`;
      if (!String.isNullOrWhiteSpace(pageLayoutId)) {
        newPageRequestUrl += `&pageLayoutId=${pageLayoutId}`;
      }

      var triggeredData = localStorage.getItem("TriggeredData");
      if (this.windowOpener && !String.isNullOrWhiteSpace(triggeredData)) {
        newPageRequestUrl += `&triggeredData=${triggeredData}`;
      }

      this.$appAxios
        .get(newPageRequestUrl)
        .then((response) => {
          this.pageData = response.data;
          this.$store.commit("setPageDto", this.pageData);

          if (this.pageData.isOk) {
            if (
              String.isNullOrWhiteSpace(this.pageData.layout) ||
              String.isNullOrWhiteSpace(this.pageData.layout.layout)
            ) {
              this.$store.commit("setPageLayoutAvailable", false);
              this.$router.push({
                name: "PageLayoutNone",
                params: { key: this.$route.params.key },
              });
              return;
            }

            this.formShow = true;
            if (this.pageData.layout.layout) {
              this.$root.operationAfterPageLoadForSetScripts();
              this.$store.commit("setPageLayoutAvailable", true);
              this.designedItems = JSON.parse(this.pageData.layout.layout);
            }

            var customObjectName = this.pageData.name,
              customObjectPluralName = this.pageData.customObjectPluralName;
            if (this.$store.state.isMultiLanguage) {
              var localizationValue =
                this.$store.getters.getLocalizationValuesByParameters({
                  itemFormulaName: this.pageData.layout.customObjectFormulaName,
                  itemTypeId: 1,
                });
              if (localizationValue) {
                customObjectName = localizationValue.value;
              }

              var localizationValueForPluralName =
                this.$store.getters.getLocalizationValuesByParameters({
                  itemFormulaName: this.pageData.layout.customObjectFormulaName,
                  itemTypeId: 11,
                });
              if (localizationValueForPluralName) {
                customObjectPluralName = localizationValueForPluralName.value;
              }
            }

            var title = this.$t(
              "Set.NewFormat",
              {},
              { locale: this.$store.state.activeLang }
            ).replaceAll("OBJECT_NAME", customObjectName);

            this.$root.changeDocumentTitle(title);
            setCurrentPageBreadcrumbs(title, [
              {
                name: customObjectPluralName,
                isRouteLink: true,
                html: `/set/list/${this.$route.params.key}`,
              },
              {
                name: title,
              },
            ]);
          } else {
            this.$root.changeDocumentTitle(
              this.$t("Components.LostWave.Title"),
              {},
              { locale: this.$store.state.activeLang }
            );
            setCurrentPageBreadcrumbs(
              this.$t(
                "Components.LostWave.Title",
                {},
                { locale: this.$store.state.activeLang }
              ),
              []
            );
            this.responseError.push(response.data.msg);
          }
        })
        .catch(function (error) {
          //TODO:Error
          // alert(error);
        });
    },
    onClear() {
      var self = this,
        textFields = $(
          "form#newForm input:not(.system-hidden-field):not(.btn-check), form#newForm textarea"
        );
      $.each(textFields, function (i, el) {
        var $el = $(el),
          values = $el.closest(".page-item").data("oncleardefaultvalue");

        if (String.isNullOrWhiteSpace(values)) {
          $el.val("");
        } else {
          $el.val(values);
        }
      });

      var checkboxItems = $("form#newForm .form-check-input");
      $.each(checkboxItems, function (i, el) {
        var $el = $(el),
          value = $el.closest(".page-item").data("oncleardefaultvalue");

        $el.prop(
          "checked",
          String.isNullOrWhiteSpace(value) ? false : Boolean.toBool(value)
        );
      });

      var radioSelectListItems = $("form#newForm .sx-radio-select");
      $.each(radioSelectListItems, function (i, el) {
        var parent = $(el),
          pageItem = parent.closest(".page-item"),
          fieldPublicId = pageItem.data("publicid"),
          element = $(`[name='${fieldPublicId}']`),
          value = pageItem.data("oncleardefaultvalue"),
          fieldIsMultiple = pageItem.data("ismultiplevalue"),
          clearButton = $(`#sx-radio-select-clear-${fieldPublicId}`);

        if (String.isNullOrWhiteSpace(value)) {
          element.prop("checked", false);
          clearButton.addClass("hidden");
        } else {
          clearButton.removeClass("hidden");
          if (fieldIsMultiple) {
            value.split(self.$systemSeparator).forEach((currentValue) => {
              $(`[value='${currentValue}']`).prop("checked", true);
            });
          } else {
            $(`[value='${value}']`).prop("checked", true);
          }
        }
      });

      var selectElements = $(
        'div.page-item[data-inputtype="select"]:not([data-isradio="true"])'
      );
      $.each(selectElements, function (i, el) {
        var $el = $(el),
          currentFieldPublicId = $el.data("publicid"),
          select = $("#" + currentFieldPublicId),
          defaultValueId = $el.data("oncleardefaultvalueid"),
          defaultValue = $el.data("oncleardefaultvalue"),
          isMultiple = $el.data("ismultiplevalue"),
          field = self.pageData.fields
            .find((f) => f.isMainObject)
            .fieldModels.find((f) => f.publicId === currentFieldPublicId),
          isNullValue =
            String.isNullOrWhiteSpace(defaultValueId) ||
            String.isNullOrWhiteSpace(defaultValue) ||
            (!String.isNullOrWhiteSpace(defaultValueId) &&
              defaultValueId.includes("error")) ||
            (!String.isNullOrWhiteSpace(defaultValue) &&
              defaultValue.includes("error"));

        if (isNullValue) {
          self.$root.select2SetValue(select, isMultiple);
        } else {
          self.$root.select2SetValue(
            select,
            isMultiple,
            defaultValueId,
            defaultValue
          );
          self.$root.select2PagedSelectlistSelection(field, defaultValueId);
          self.$root.calculateTriggerField(field);
        }

        if (field.fieldType == "Lookup") {
          var detailIcon = $el.find('[id*="open_detail"]');
          if (!detailIcon) return;

          if (isNullValue) {
            detailIcon.removeAttr("href").addClass("hidden");
          } else {
            detailIcon
              .attr(
                "href",
                `#/set/detail/${field.lookupObjectKey}/${defaultValueId}`
              )
              .removeClass("hidden");
          }
        }
      });
    },
    setValue(obj, itemId) {
      var item = $("#" + itemId);
      var pageItem = item.closest(".form-group");
      if (pageItem.data("inputtype") === "select") {
        if (pageItem.data("isradio"))
          obj.fData[item.attr("id")] = pageItem
            .find("label.active")
            .text()
            .trim();
        else
          obj.fData[item.attr("id")] =
            item.select2("data") !== null
              ? item
                  .select2("data")
                  .map((u) => u.text)
                  .join(this.$systemSeparator)
              : "";
      } else {
        obj.fData[item.attr("id")] = item.val();
      }
      localStorage.setItem("NewLookupTabData", JSON.stringify(obj));
    },
    prepareWindowOpenerForm() {
      var self = this,
        lookupTabData = localStorage.getItem("NewLookupTabData");
      if (lookupTabData) {
        var obj = JSON.parse(lookupTabData);
        var fieldIds = obj.fieldIds.split(self.$systemSeparator);
        var lFields = "#" + fieldIds.join(", #");

        $("body").on("change", lFields, function () {
          var item = $(this);
          self.setValue(obj, item.attr("id"));
        });

        $.each(fieldIds, function (i, id) {
          self.setValue(obj, id);
        });
      }

      var timerRepeatCount = 0;
      var timer = setInterval(function () {
        var lookupInfo = localStorage.getItem("LookupInfo");
        if (lookupInfo) {
          var info = JSON.parse(lookupInfo),
            selectorId = "";
          if (!String.isNullOrWhiteSpace(info.fId)) {
            selectorId = "#" + info.fId;
          } else {
            var selector = '[data-lookupobjectid="' + info.coId + '"]';
            selectorId = "#" + $(selector).data("publicid");
          }

          if (timerRepeatCount > 5) {
            clearInterval(timer);
            return;
          }

          if ($(selectorId).length > 0) {
            var currentField = self.pageData.fields
              .find((f) => f.isMainObject)
              .fieldModels.find(
                (f) => f.publicId == $(selector).data("publicid")
              );
            if (String.isNullOrWhiteSpace(currentField)) {
              clearInterval(timer);
              return;
            }

            if (!localStorage.getItem("TriggeredData")) {
              self.$root.select2SetValue(
                $(selectorId),
                currentField.isMultipleValue,
                info.rId,
                info.val
              );

              $(selectorId)
                .closest(".select2-invalid")
                .addClass("select2-valid")
                .removeClass("select2-invalid");
            } else {
              //clearing ls triggered data
              localStorage.removeItem("TriggeredData");
            }

            $(selectorId).prop("disabled", true);

            self.$root.select2PagedSelectlistSelection(currentField, info.rId);
            self.$root.calculateTriggerField(currentField);

            var detailIcon = document.getElementById(
              `open_detail_${currentField.publicId}`
            );
            if (!currentField.isMultipleValue && detailIcon) {
              detailIcon.classList.remove("hidden");
              detailIcon.setAttribute(
                "href",
                `#/set/detail/${currentField.lookupObjectKey}/${info.rId}`
              );
            }

            clearInterval(timer);
          } else {
            timerRepeatCount++;
          }
        }
      }, 1500);
    },
    onRecordInsert(submitType, buttonName, isForcingSave = false) {
      var form = $("form#newForm"),
        self = this,
        model = this.$root.modelCreate("form#newForm"),
        allFields = this.pageData.fields.find(
          (f) => f.isMainObject
        ).fieldModels;

      this.insertResponseValidationModel = {
        showValidations: false,
        msg: "",
        message: "",
        errors: [],
        modalErrors: [],
        warnings: [],
        modalWarnings: [],
        informations: [],
        modalInformations: [],
        businessRuleErrors: [],
      };

      if (!form[0].checkValidity() || !this.$root.forceFormValidation(form)) {
        form.addClass("was-validated");

        this.$root.elementScrollToTop($("html, body"));
        this.insertResponseValidationModel.showValidations = true;
        this.insertResponseValidationModel.errors = this.$root.formValidation(
          form,
          model.values,
          allFields
        );

        return;
      }

      var button = $(`.${buttonName}`),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      model.isButtonSaveAndNew = submitType == 0 ? false : true;
      model.isButtonSaveAndClose = submitType == 2 ? true : false;
      model.isForcingSave = isForcingSave;

      if (model.values.length > 0) {
        model.values = model.values
          .filter(function (f) {
            return (
              (String.isNullOrWhiteSpace(f.value) &&
                !String.isNullOrWhiteSpace(f.oldValue)) ||
              !String.isNullOrWhiteSpace(f.value)
            );
          })
          .map(function (m) {
            return {
              key: m.key,
              value: m.value,
            };
          });
      }
      this.model = model;

      this.$appAxios
        .post("/rws-SetController-New", this.model)
        .then((response) => {
          var result = response.data,
            brErrors = result.businessRuleResponse.businessRuleResponses,
            detailRouterObject = {
              name: "detail",
              params: {
                key: this.$route.params.key,
                id: result.recordPublicId,
              },
              query: {
                pageLayoutId: this.$route.query.pageLayoutId,
              },
            };

          if (!this.windowOpener) {
            this.$store.commit("setPagesCRUDResult", result);
          }

          if (result.isOk) {
            createToast(
              this.$t(
                "BaseModelFields.SuccessMessage",
                {},
                { locale: this.$store.state.activeLang }
              ),
              {
                showIcon: true,
                position: "top-right",
                type: "success",
                transition: "zoom",
              }
            );
            self.isSavedData = true;
            if (submitType == 0) {
              self.$router.push(detailRouterObject);
            } else if (submitType == 1) {
              self.savedRecords.push({
                id: result.recordPublicId,
                createDate: new Date(),
              });
              self.onClear();
              this.pageData.recordPublicId = result.newRecordPublicId;
              window.scrollTo(0, 0);
              firstSpan.show();
              loadingBlock.hide();
              disabledButtons.prop("disabled", false);
              form.removeClass("was-validated");
            } else if (submitType == 2) {
              if (window.opener) {
                var lookupTabData = localStorage.getItem("NewLookupTabData");
                if (lookupTabData) {
                  var obj = JSON.parse(lookupTabData),
                    texts = [],
                    fields = this.pageData.fields.find(
                      (f) => f.isMainObject
                    ).fieldModels;

                  obj.fieldIds
                    .split(this.$systemSeparator)
                    .forEach((fieldId) => {
                      var currentField = fields.find(
                        (f) => f.publicId === fieldId
                      );
                      if (!currentField) return;

                      if (obj.fData[fieldId]) {
                        texts.push(obj.fData[fieldId]);
                      } else if (currentField.fieldType.includes("Formula")) {
                        var formItemEl = $(`#${fieldId}`);
                        if (formItemEl) {
                          texts.push(formItemEl.val());
                        }
                      }
                    });
                  obj.data = {
                    id: result.recordPublicId,
                    text: texts,
                  };
                  localStorage.setItem("NewLookupTabData", JSON.stringify(obj));
                }

                localStorage.removeItem("LookupInfo");
                localStorage.removeItem("TriggeredData");

                if (
                  this.insertResponseValidationModel.businessRuleErrors
                    .length == 0
                ) {
                  setTimeout(() => {
                    window.close();
                  }, 900);
                }

                $(".btn-save-and-close").remove();
              }
            } else {
              if (window.opener) {
                localStorage.setItem("UserUsedSaveAndContinue", true);
                localStorage.removeItem("NewLookupTabData");
                localStorage.removeItem("LookupInfo");
                localStorage.removeItem("TriggeredData");
              }

              self.$router.push(detailRouterObject);
            }

            this.$root.createEvent("SX_NEW_RECORD_CREATED", {
              title: "New Record Created",
              message: "Event triggered when new record created",
              result: result,
              model: this.model,
            });
          } else {
            this.$root.elementScrollToTop($("html, body"));
            this.insertResponseValidationModel = {
              msg: result.msg,
              message: result.message,
              errors: this.$root.getValidationMessages(
                result.validations,
                false,
                1,
                allFields
              ),
              modalErrors: this.$root.getValidationMessages(
                result.validations,
                true,
                1,
                allFields
              ),
              warnings: this.$root.getValidationMessages(
                result.validations,
                false,
                2,
                allFields
              ),
              modalWarnings: this.$root.getValidationMessages(
                result.validations,
                true,
                2,
                allFields
              ),
              informations: this.$root.getValidationMessages(
                result.validations,
                false,
                3,
                allFields
              ),
              modalInformations: this.$root.getValidationMessages(
                result.validations,
                true,
                3,
                allFields
              ),
              businessRuleErrors: brErrors,
            };

            //unknown error
            if (
              String.isNullOrWhiteSpace(
                this.insertResponseValidationModel.msg
              ) &&
              String.isNullOrWhiteSpace(
                this.insertResponseValidationModel.message
              ) &&
              this.insertResponseValidationModel.errors.length == 0 &&
              this.insertResponseValidationModel.warnings.length == 0 &&
              this.insertResponseValidationModel.informations.length == 0 &&
              this.insertResponseValidationModel.modalErrors.length == 0 &&
              this.insertResponseValidationModel.modalWarnings.length == 0 &&
              this.insertResponseValidationModel.modalInformations.length == 0
            ) {
              this.insertResponseValidationModel.msg = this.$t(
                "BaseModelFields.AnUnknownErrorHasOccurred",
                {},
                { locale: this.$store.state.activeLang }
              );
            }

            this.insertResponseValidationModel.showValidations =
              !String.isNullOrWhiteSpace(
                this.insertResponseValidationModel.msg
              ) ||
              !String.isNullOrWhiteSpace(
                this.insertResponseValidationModel.message
              ) ||
              this.insertResponseValidationModel.errors.length > 0 ||
              this.insertResponseValidationModel.warnings.length > 0 ||
              this.insertResponseValidationModel.informations.length > 0;

            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
          }

          var modalShow =
            this.insertResponseValidationModel.businessRuleErrors.length > 0 ||
            this.insertResponseValidationModel.modalErrors.length > 0 ||
            this.insertResponseValidationModel.modalWarnings.length > 0 ||
            this.insertResponseValidationModel.modalInformations.length > 0;
          if (modalShow) {
            showModal(document.getElementById("modalValidationMessage"));
          }
        })
        .catch(function (error) {
          // alert(error);
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
        });
    },
  },
  unmounted() {
    this.windowOpener = null;
    // localStorage.removeItem("NewLookupTabData");
  },
};
</script>
